import { render, staticRenderFns } from "./DetailMessage.vue?vue&type=template&id=16c73cb8&"
import script from "./DetailMessage.vue?vue&type=script&lang=ts&"
export * from "./DetailMessage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardTitle,VContainer})


/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/uSacGCCn/0/ucarok/mobileservices-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('16c73cb8')) {
      api.createRecord('16c73cb8', component.options)
    } else {
      api.reload('16c73cb8', component.options)
    }
    module.hot.accept("./DetailMessage.vue?vue&type=template&id=16c73cb8&", function () {
      api.rerender('16c73cb8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/message/DetailMessage.vue"
export default component.exports