var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-card",
        {
          staticClass: "mx-auto pl-5 pr-5 pb-5",
          attrs: { "max-width": "640px" },
        },
        [
          _c("v-card-title", [_vm._v(_vm._s(_vm.mechanicName))]),
          _c("sms-sender", {
            attrs: {
              messages: _vm.messages,
              userId: _vm.userId,
              tupletId: _vm.tupletId,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }